<template>
  <v-container>
    <v-breadcrumbs
      v-if="audit"
      class="py-2"
      :items="[
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: `Chantier`,
          disabled: false,
          to: { name: 'SiteElevators', params: { id: audit.elevator.site.id } },
        },
        {
          text: `Audit de l'ascenseur ${audit.elevator.code}`,
          disabled: true,
        },
      ]"
    />
    <div v-if="audit" class="mt-2">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col sm="12" md="9" class="d-flex align-center">
              <div>
                <div class="text-h4 font-weight-regular mb-2 mt-3">
                  <v-icon size="2em" class="ml-3 mr-5"
                    >mdi-clipboard-text</v-icon
                  >
                  Audit
                </div>
                <div class="mt-3">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-clock</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ audit.createdAt | formatDate }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Date de création
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-col>
            <v-col sm="12" md="3">
              <v-btn block color="info" text @click="openDocumentExternal">
                <v-icon left>mdi-download</v-icon>
                Télécharger
              </v-btn>
              <v-btn
                block
                color="secondary"
                class="mt-2"
                text
                @click="fetchData"
              >
                <v-icon left>mdi-refresh</v-icon>
                Mettre à jour
              </v-btn>
              <v-btn
                v-if="$store.state.auth.user.isAdmin"
                block
                class="mt-2"
                color="error"
                text
                @click="$refs.removeForm.open(audit)"
              >
                <v-icon left>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-alert
        border="left"
        type="error"
        class="mt-3"
        v-if="!documentMightBeAvailable && !audit.document.key"
      >
        Le document n'est pas encore disponible. Veuillez reessayer plus tard.
      </v-alert>
      <div
        v-if="documentMightBeAvailable && !audit.document.key"
        class="d-flex justify-center align-center flex-column ma-5 mt-9"
      >
        <v-progress-circular size="50" color="primary" indeterminate />
        <div class="mt-5">
          Veuillez patientez pendant que nous générons le document...
        </div>
      </div>
      <v-card v-if="audit.document.key != null" class="mt-3">
        <v-card-text class="d-flex justify-center">
          <VuePdfjsWrapper
            :pdf-src="audit.document.url"
            is-render-text
            :scale="pdfScale"
          />
        </v-card-text>
      </v-card>
      <RemoveItem
        resource="audits"
        title="cet audit"
        ref="removeForm"
        @finished="
          $router.push({
            name: 'ViewSite',
            params: { id: audit.elevator.site.id },
          })
        "
      />
    </div>
  </v-container>
</template>

<script>
import VuePdfjsWrapper from "vue-pdfjs-wrapper";

import pdf from "vue-pdf";
import RemoveItem from "../../components/RemoveItem.vue";

import { DateTime } from "luxon";

export default {
  components: {
    pdf,
    RemoveItem,
    VuePdfjsWrapper,
  },
  data: () => ({
    audit: null,
    sse: null,
    documentMightBeAvailable: false,
  }),
  mounted() {
    this.fetchData();
  },
  computed: {
    pdfScale() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0.7;
        case "sm":
          return 0.8;
        case "md":
          return 1;
        case "lg":
          return 1.25;
        case "xl":
          return 1.5;
        default:
          return 1;
      }
    },
  },
  methods: {
    fetchData() {
      const url = new URL(process.env.VUE_APP_MERCURE_HUB);
      url.searchParams.append(
        "topic",
        process.env.VUE_APP_MERCURE_TOPIC + "/audits/" + this.$route.params.id
      );

      let sse = new EventSource(url);
      sse.onmessage = ({ data }) => {
        const update = JSON.parse(data);
        console.debug("received audit update", update);
        if (update.document && update.document.key) {
          this.audit.document.key = update.document.key;
          this.audit.document.url = update.document.url;
          this.documentMightBeAvailable = true;
          console.debug("got key", update.document.key);
          this.$store.commit("alert/add", {
            type: "success",
            message: "Le document vient d'être généré.",
          });
          this.$forceUpdate();
        }
      };

      this.$store
        .dispatch("audits/fetchOne", this.$route.params.id)
        .then((res) => {
          this.audit = res;
          console.debug("Got audit", this.audit);
          // documentMightBeAvailable if created at is less than 5 minutes
          const diffInMinutes = Math.abs(
            DateTime.fromISO(this.audit.createdAt).diffNow().as("minutes")
          );
          console.debug("Creation diff in minutes:", diffInMinutes);
          this.documentMightBeAvailable = diffInMinutes < 5;

          console.debug("Audit url", this.audit.document.url);
        });
    },
    openDocumentExternal() {
      // open the audit document in new tab
      window.open(this.audit.document.url, "_blank");
    },
  },
};
</script>

<style>
#pdf-page-container-1 {
  display: none;
}

.theme--dark .pdf-canvas-layer {
  filter: invert(1);
}
</style>
